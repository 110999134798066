.App {
  font-family: sans-serif;
  text-align: center;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.alert {
  padding: 4px;
  background-color: #530052; /* Red */
  border-radius: 3px;
  color: white;
  margin-bottom: 15px;
}

