.middle,.center{
    width: 120px;
    margin: auto;
    width: 25%;
    border: 0px solid;
    padding: 5px;
    }
    .switch{
    padding: 5px;
    margin-top: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    margin: auto;
    }
    .result{
        width: 120px;
        margin: auto;
        width: 40%;
        border: 5px solid forestgreen;
        padding: 10px;
    }
    
    h3, button, .switch{
    color: forestgreen;
    }
    .button {
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        padding: 16px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        transition-duration: 0.4s;
        cursor: pointer;
        border-radius: 10px;
        cursor: pointer;
      }
      
      .buttonConvert {
        background-color: white; 
        color: black; 
        border: 2px solid #4CAF50;
        border-radius: 10px;
        cursor: pointer;
      }
      
      .buttonConvert:hover {
        background-color: #4CAF50;
        color: white;
        border-radius: 10px;
        cursor: pointer;
      }
    
     
      .container{
        display: flex;
        }
        .fixed{
            width: 200px;
        }
        .flex-item{
            flex-grow: 1;
        }
