h1 {
    text-align: center;
    color: #a8a8a8;
  }
  
  form_feedback {
    max-width: auto;
    text-align: center;
    border-radius: 8px;
    height: 520px;
  }
  
  .feedbackForm {
    width: 400px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid gray;
    position:fixed;
    bottom: 2.5em;
    right: 9.5em;
    bottom: 4.3em;
    transition: all 2s ease;
    background-color: rgba(39, 3, 122, 0.473);
    
}
  .feedbackForm.right { 
    right: 0;  
  }
  
  .feedbackForm.right.move {
    right: 100%;
    transform: translate(100%, 0);
  }
  
  .titleform
  {
    font-size: 25px;
    padding: 1em;
    width: auto;
    background-color: rgba(54, 54, 87, 0.705);
    margin-top: 0em;
    border-radius: 8px 8px 0px 0px;
    color: white;
    height: auto;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 1em;
  
  }
  
  input,
  textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 8px;
    display:ruby-base;
    font-size: 14px;
    width: 90%;
    font-family: 'Merriweather', sans-serif;
    resize: none;
    background-color: rgb(244, 252, 252);
    
  }
  
  #input-submit {
    color: white;
    background: #e74c3c;
    cursor: pointer;
    width: 30%;
    margin-left: 2.3em;
    margin-bottom: 2em;
    float:left;
  
  }
  
  .bt-feedback{
    color: white;
    background: #460202;
    background-color: #e70d0d;
    cursor: pointer;
    width: auto;
    border-radius: 8px 8px 0px 0px;
    float:right;
    position:fixed;
    bottom: 135px;
    right: -60px;
    transform: rotate(-90deg);
    font-weight: 10px;
    font-size:xx-large;
  }
  
  .btn-close-form{
    top: -1px;
    right: -1px;
    position: absolute;
    border: 0;
    border-radius: 0px 8px 0px 0px;
    background-color: rgb(155, 2, 2);
    font-size: 15px;
  
  }
  
  .btn-close-form:hover{
    background-color: midnightblue;
  }
  
  #input-submit:hover {
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
  
  textarea {
    height: 200px;
  }
  
  .half {
    float:none;
    width: auto;
    margin-bottom: 1em;
    margin-left: 1em;
    margin-right: 1em;
  }
  
  
  
  @media (max-width: 400px) {
    .half {
      width: 100%;
      float: none;
      margin-bottom: 0;
      /* transition: top 2s ease; */
    }
  }
  
  .cf:before,
  .cf:after {
    content: ' ';
    display: table;
    /* transition: left 2s ease; */
  }
  
  .cf:after {
    clear: both;
    /* transition: top 2s ease; */
  }
